import styled, { keyframes } from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: ${gridTheme.maxContentWidth}px;
  justify-content: center;
  margin-top: 64px;
  gap: 64px;
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    align-items: center;
  }
`;

const flow = keyframes`
  from {
    transform: skew(0.5deg);
  }
  to {
    transform: skew(-0.1deg);
  }
`;

const pulse = keyframes`
  from {
    fill: #00416A;
  }
  to {
    fill: #8FDBC7;
  }
`;

export const ContentContainer = styled.div``;

export const IllustrationContainer = styled.div`
  width: 100%;
  max-width: 492px;
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    display: none;
  }
  svg {
    .backdrop-illus {
      animation-direction: alternate;
      animation-duration: 6s;
      animation-iteration-count: infinite;
      animation-name: ${pulse};
      transform-origin: center top;
    }
    .leaf {
      animation-direction: alternate;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-name: ${flow};
      transform-origin: center top;
    }
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  h2 {
    color: ${theme.palette.blue.main};
    font-size: 100px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: -0.02em;
    line-height: 120%;
    text-shadow: 4px 0px 0px ${theme.palette.yellow.main};
    @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
      font-size: 30px;
    }
  }
`;

export const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  width: 494px;
  height: 405px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  &:before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 50%;
    width: 403px;
    height: 403px;
    border-radius: 50%;
    background-color: ${theme.palette.blue.lightest};
    content: "";
    transform: translateX(-50%);
    @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
      width: 193px;
      height: 193px;
    }
  }
  h1 {
    height: 280px;
    align-items: center;
    color: ${theme.palette.blue.main};
    font-size: 300px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: -0.02em;
    text-shadow: 20px 0px 0px ${theme.palette.yellow.main};
    @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
      font-size: 130px;
    }
  }
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    width: 240px;
    height: 196px;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  row-gap: 24px;
  p {
    max-width: 432px;
  }
`;
