import React from "react";

export const OrangeArrow = (): JSX.Element => {
  return (
    <svg fill="none" height="42" viewBox="0 0 197 42" width="197" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_892_203855)">
        <path d="M5 21H175" stroke="#F85E00" strokeLinecap="round" strokeWidth="10" />
        <path d="M169 5L185 21L169 37" stroke="#F85E00" strokeLinecap="round" strokeWidth="10" />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="42"
          id="filter0_d_892_203855"
          width="196.071"
          x="0"
          y="0"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.835294 0 0 0 0 0 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_892_203855" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_892_203855" mode="normal" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
