import { useFrontpage, withDatoFrontpage } from "@byko/lib-api-dato-client";
import { BykoFrontPage } from "@byko/scene-byko-front-page";
import { ErrorScene } from "@byko/scene-error";

import type { BykoFrontPage as _BykoFrontPage } from "@byko/lib-api-dato-client";
interface Props {
  data?: _BykoFrontPage | null;
  notFound: boolean;
  internalError: boolean;
}

export const Frontpage = ({ notFound, internalError, data: initialData }: Props): JSX.Element | null => {
  const { data } = useFrontpage({ initialData, forPro: false });
  if (notFound || (internalError && !data)) {
    return <ErrorScene code={500} />;
  }
  if (!data || "error" in data) {
    return null;
  }
  return <BykoFrontPage page={data} />;
};

export default withDatoFrontpage(Frontpage, false);
