import NextLink from "next/link";
import React, { useMemo } from "react";

import { Button } from "@byko/component-buttons";
import { PageContainer } from "@byko/component-page-container";
import { H2, H3, PLarge } from "@byko/component-typography";
import { LongArrowRightIcons } from "@byko/lib-icons";
import { Error400, Error500 } from "@byko/lib-illustrations";
import { theme } from "@byko/lib-styles";

import { OrangeArrow } from "./orange-arrow";
import {
  ArrowContainer,
  Container,
  ContentContainer,
  DescriptionContainer,
  HeaderContainer,
  IllustrationContainer,
} from "./styles";

import type { ErrorSceneProps } from "./interface";
import type { ReactNode } from "react";

export const ErrorScene = ({ code }: ErrorSceneProps): JSX.Element => {
  const Illustration = useMemo(() => {
    if (code >= 500) {
      return Error500;
    } else {
      return Error400;
    }
  }, [code]);

  const Parent = useMemo(() => {
    // 404 error - ok to use NextJS to link
    if (code === 404) {
      return ({ children }: { children: ReactNode }) => (
        <NextLink href="/" legacyBehavior={true}>
          {children}
        </NextLink>
      );
    }
    return ({ children }: { children: ReactNode }) => <>{children}</>;
  }, [code]);

  return (
    <PageContainer flexDirection="column" offsetNav={false}>
      <Container>
        <ContentContainer>
          <HeaderContainer>
            <H2>{code}</H2>
            <ArrowContainer>
              <OrangeArrow />
              <H3 textColor={theme.palette.blue.dark}>Villa</H3>
            </ArrowContainer>
          </HeaderContainer>
          <DescriptionContainer>
            <PLarge textColor={theme.palette.blue.dark}>
              Úps þessi síða virðist ekki vera til, eða er ekki tilbúin. Við höldum áfram að byggja hana en smellt þú
              þér aftur á forsíðuna.
            </PLarge>
            <Parent>
              <Button as="href" buttonColor="blueButton" href="/" icon={LongArrowRightIcons} label="Forsíða" />
            </Parent>
          </DescriptionContainer>
        </ContentContainer>
        <IllustrationContainer>
          <Illustration size={"100%"} />
        </IllustrationContainer>
      </Container>
    </PageContainer>
  );
};
